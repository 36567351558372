<template>
  <div class="">
    <div
      v-if="error"
      class="d-flex flex-column flex-grow-1 align-items-center justify-content-center"
      style="height: 90vh"
    >
      <div class="">
        <LazyImage
          :src="`https://images.quizell.com/website/404.png`"
          style="height: 50vh"
          :title="`Error 404`"
          :alt="`Error 404`"
          class="Img-Fluid"
        />
      </div>
      <div class="TitleDiv">
        <p class="title">Oops! Something went wrong.</p>
        <p class="title">Please Re-install the App again</p>
      </div>
    </div>

    <div class="">
      <VerifyWixUserEmail
        :token="token"
        v-if="is_random_email"
      ></VerifyWixUserEmail>
    </div>
  </div>
</template>

<script>
import VerifyWixUserEmail from "./VerifyWixUserEmail.vue";
import axios from "axios";
export default {
  components: {
    VerifyWixUserEmail,
  },
  data() {
    return {
      error: false,
      is_random_email: null,
      token: null,
    };
  },
  mounted() {
    this.loginForToken();
  },
  methods: {
    async loginForToken() {
      try {
        localStorage.removeItem("user_access_token");
        let data = { instance: this.$route.query.instance };

        const response = await axios.post(`/loginWixUser`, data);

        if (response.status == 200) {
          let { is_random_email ,access_token } = response.data;
          if (is_random_email) {
            this.token = access_token;
            this.is_random_email = is_random_email;
          } else {
            localStorage.setItem(
              "user_access_token",
              access_token
            );
            this.$router.push("/manage/quizzes").then(() => { this.$router.go(0) })
          }
        }
      } catch (error) {
        this.error = true;
        console.log(error.response.data.error);
      }
    },
  },
};
</script>

<style scoped>
.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 136%;

  text-align: center;

  color: #161e34;
}
</style>
